import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDistricts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/district', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchDistrict(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/district/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCitiesByRegion(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/region/${id}/cities`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteDistrict(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/district/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addDistrict(ctx, districtData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/district', districtData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRegions(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/region/list')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCities(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/city/list')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
